// Sprite credit: https://github.com/PokeAPI/sprites
// Hisui sprites: https://www.smogon.com/forums/threads/smogon-sprite-project.3647722/post-9195974

const pokemon = [
  {
    name: "Bulbasaur",
    id: 1,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Ivysaur",
    id: 2,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Venusaur",
    id: 3,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Charmander",
    id: 4,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Charmeleon",
    id: 5,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Charizard",
    id: 6,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Squirtle",
    id: 7,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Wartortle",
    id: 8,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Blastoise",
    id: 9,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Caterpie",
    id: 10,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Metapod",
    id: 11,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Butterfree",
    id: 12,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Weedle",
    id: 13,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Kakuna",
    id: 14,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Beedrill",
    id: 15,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Pidgey",
    id: 16,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Pidgeotto",
    id: 17,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Pidgeot",
    id: 18,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Rattata",
    id: 19,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Raticate",
    id: 20,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Spearow",
    id: 21,
    shinyAvailable: true,
  },
  {
    name: "Fearow",
    id: 22,
    shinyAvailable: true,
  },
  {
    name: "Ekans",
    id: 23,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Arbok",
    id: 24,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Pikachu",
    id: 25,
    shinyAvailable: true,
  },
  {
    name: "Raichu",
    id: 26,
    shinyAvailable: true,
  },
  {
    name: "Sandshrew",
    id: 27,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Sandslash",
    id: 28,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Nidoran ♀",
    id: 29,
    shinyAvailable: true,
    gender: "female",
    shadow: true,
  },
  {
    name: "Nidorina",
    id: 30,
    shinyAvailable: true,
    gender: "female",
    shadow: true,
  },
  {
    name: "Nidoqueen",
    id: 31,
    shinyAvailable: true,
    gender: "female",
    shadow: true,
  },
  {
    name: "Nidoran ♂",
    id: 32,
    shinyAvailable: true,
    gender: "male",
    shadow: true,
  },
  {
    name: "Nidorino",
    id: 33,
    shinyAvailable: true,
    gender: "male",
    shadow: true,
  },
  {
    name: "Nidoking",
    id: 34,
    shinyAvailable: true,
    gender: "male",
    shadow: true,
  },
  {
    name: "Clefairy",
    id: 35,
    shinyAvailable: true,
  },
  {
    name: "Clefable",
    id: 36,
    shinyAvailable: true,
  },
  {
    name: "Vulpix",
    id: 37,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Ninetales",
    id: 38,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Jigglypuff",
    id: 39,
    shinyAvailable: true,
  },
  {
    name: "Wigglytuff",
    id: 40,
    shinyAvailable: true,
  },
  {
    name: "Zubat",
    id: 41,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Golbat",
    id: 42,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Oddish",
    id: 43,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Gloom",
    id: 44,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Vileplume",
    id: 45,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Paras",
    id: 46,
    shinyAvailable: true,
  },
  {
    name: "Parasect",
    id: 47,
    shinyAvailable: true,
  },
  {
    name: "Venonat",
    id: 48,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Venomoth",
    id: 49,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Diglett",
    id: 50,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Dugtrio",
    id: 51,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Meowth",
    id: 52,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Persian",
    id: 53,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Psyduck",
    id: 54,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Golduck",
    id: 55,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Mankey",
    id: 56,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Primeape",
    id: 57,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Growlithe",
    id: 58,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Arcanine",
    id: 59,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Poliwag",
    id: 60,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Poliwhirl",
    id: 61,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Poliwrath",
    id: 62,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Abra",
    id: 63,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Kadabra",
    id: 64,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Alakazam",
    id: 65,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Machop",
    id: 66,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Machoke",
    id: 67,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Machamp",
    id: 68,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Bellsprout",
    id: 69,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Weepinbell",
    id: 70,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Victreebel",
    id: 71,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Tentacool",
    id: 72,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Tentacruel",
    id: 73,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Geodude",
    id: 74,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Graveler",
    id: 75,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Golem",
    id: 76,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Ponyta",
    id: 77,
    shinyAvailable: true,
  },
  {
    name: "Rapidash",
    id: 78,
    shinyAvailable: true,
  },
  {
    name: "Slowpoke",
    id: 79,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Slowbro",
    id: 80,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Magnemite",
    id: 81,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Magneton",
    id: 82,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Farfetch'd",
    id: 83,
    shinyAvailable: true,
  },
  {
    name: "Doduo",
    id: 84,
    shinyAvailable: true,
  },
  {
    name: "Dodrio",
    id: 85,
    shinyAvailable: true,
  },
  {
    name: "Seel",
    id: 86,
    shinyAvailable: true,
  },
  {
    name: "Dewgong",
    id: 87,
    shinyAvailable: true,
  },
  {
    name: "Grimer",
    id: 88,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Muk",
    id: 89,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Shellder",
    id: 90,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Cloyster",
    id: 91,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Gastly",
    id: 92,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Haunter",
    id: 93,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Gengar",
    id: 94,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Onix",
    id: 95,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Drowzee",
    id: 96,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Hypno",
    id: 97,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Krabby",
    id: 98,
    shinyAvailable: true,
  },
  {
    name: "Kingler",
    id: 99,
    shinyAvailable: true,
  },
  {
    name: "Voltorb",
    id: 100,
    shinyAvailable: true,
    shadow: true,
    gender: "none",
  },
  {
    name: "Electrode",
    id: 101,
    shinyAvailable: true,
    shadow: true,
    gender: "none",
  },
  {
    name: "Exeggcute",
    id: 102,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Exeggutor",
    id: 103,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Cubone",
    id: 104,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Marowak",
    id: 105,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Hitmonlee",
    id: 106,
    shinyAvailable: true,
    gender: "male",
    shadow: true,
  },
  {
    name: "Hitmonchan",
    id: 107,
    shinyAvailable: true,
    gender: "male",
    shadow: true,
  },
  {
    name: "Lickitung",
    id: 108,
    shinyAvailable: true,
  },
  {
    name: "Koffing",
    id: 109,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Weezing",
    id: 110,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Rhyhorn",
    id: 111,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Rhydon",
    id: 112,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Chansey",
    id: 113,
    shinyAvailable: true,
    gender: "female",
  },
  {
    name: "Tangela",
    id: 114,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Kangaskhan",
    id: 115,
    shinyAvailable: true,
    gender: "female",
  },
  {
    name: "Horsea",
    id: 116,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Seadra",
    id: 117,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Goldeen",
    id: 118,
    shinyAvailable: true,
  },
  {
    name: "Seaking",
    id: 119,
    shinyAvailable: true,
  },
  {
    name: "Staryu",
    id: 120,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Starmie",
    id: 121,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Mr. Mime",
    id: 122,
    shinyAvailable: true,
  },
  {
    name: "Scyther",
    id: 123,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Jynx",
    id: 124,
    shinyAvailable: true,
    gender: "female",
  },
  {
    name: "Electabuzz",
    id: 125,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Magmar",
    id: 126,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Pinsir",
    id: 127,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Tauros",
    id: 128,
    shinyAvailable: true,
    gender: "male",
  },
  {
    name: "Magikarp",
    id: 129,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Gyarados",
    id: 130,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Lapras",
    id: 131,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Ditto",
    id: 132,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Eevee",
    id: 133,
    shinyAvailable: true,
  },
  {
    name: "Vaporeon",
    id: 134,
    shinyAvailable: true,
  },
  {
    name: "Jolteon",
    id: 135,
    shinyAvailable: true,
  },
  {
    name: "Flareon",
    id: 136,
    shinyAvailable: true,
  },
  {
    name: "Porygon",
    id: 137,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Omanyte",
    id: 138,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Omastar",
    id: 139,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Kabuto",
    id: 140,
    shinyAvailable: true,
  },
  {
    name: "Kabutops",
    id: 141,
    shinyAvailable: true,
  },
  {
    name: "Aerodactyl",
    id: 142,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Snorlax",
    id: 143,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Articuno",
    id: 144,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Zapdos",
    id: 145,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Moltres",
    id: 146,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Dratini",
    id: 147,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Dragonair",
    id: 148,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Dragonite",
    id: 149,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Mewtwo",
    id: 150,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Mew",
    id: 151,
    tradable: false,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Chikorita",
    id: 152,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Bayleef",
    id: 153,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Meganium",
    id: 154,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Cyndaquil",
    id: 155,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Quilava",
    id: 156,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Typhlosion",
    id: 157,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Totodile",
    id: 158,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Croconaw",
    id: 159,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Feraligatr",
    id: 160,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Sentret",
    id: 161,
    shinyAvailable: true,
  },
  {
    name: "Furret",
    id: 162,
    shinyAvailable: true,
  },
  {
    name: "Hoothoot",
    id: 163,
    shinyAvailable: true,
  },
  {
    name: "Noctowl",
    id: 164,
    shinyAvailable: true,
  },
  {
    name: "Ledyba",
    id: 165,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Ledian",
    id: 166,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Spinarak",
    id: 167,
    shinyAvailable: true,
  },
  {
    name: "Ariados",
    id: 168,
    shinyAvailable: true,
  },
  {
    name: "Crobat",
    id: 169,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Chinchou",
    id: 170,
    shinyAvailable: true,
  },
  {
    name: "Lanturn",
    id: 171,
    shinyAvailable: true,
  },
  {
    name: "Pichu",
    id: 172,
    shinyAvailable: true,
  },
  {
    name: "Cleffa",
    id: 173,
    shinyAvailable: true,
  },
  {
    name: "Igglybuff",
    id: 174,
    shinyAvailable: true,
  },
  {
    name: "Togepi",
    id: 175,
    shinyAvailable: true,
  },
  {
    name: "Togetic",
    id: 176,
    shinyAvailable: true,
  },
  {
    name: "Natu",
    id: 177,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Xatu",
    id: 178,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Mareep",
    id: 179,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Flaaffy",
    id: 180,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Ampharos",
    id: 181,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Bellossom",
    id: 182,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Marill",
    id: 183,
    shinyAvailable: true,
  },
  {
    name: "Azumarill",
    id: 184,
    shinyAvailable: true,
  },
  {
    name: "Sudowoodo",
    id: 185,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Politoed",
    id: 186,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Hoppip",
    id: 187,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Skiploom",
    id: 188,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Jumpluff",
    id: 189,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Aipom",
    id: 190,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Sunkern",
    id: 191,
    shinyAvailable: true,
  },
  {
    name: "Sunflora",
    id: 192,
    shinyAvailable: true,
  },
  {
    name: "Yanma",
    id: 193,
    shinyAvailable: true,
  },
  {
    name: "Wooper",
    id: 194,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Quagsire",
    id: 195,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Espeon",
    id: 196,
    shinyAvailable: true,
  },
  {
    name: "Umbreon",
    id: 197,
    shinyAvailable: true,
  },
  {
    name: "Murkrow",
    id: 198,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Slowking",
    id: 199,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Misdreavus",
    id: 200,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Unown",
    id: 201,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Wobbuffet",
    id: 202,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Girafarig",
    id: 203,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Pineco",
    id: 204,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Forretress",
    id: 205,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Dunsparce",
    id: 206,
    shinyAvailable: true,
  },
  {
    name: "Gligar",
    id: 207,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Steelix",
    id: 208,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Snubbull",
    id: 209,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Granbull",
    id: 210,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Qwilfish",
    id: 211,
    shinyAvailable: true,
  },
  {
    name: "Scizor",
    id: 212,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Shuckle",
    id: 213,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Heracross",
    id: 214,
    shinyAvailable: true,
  },
  {
    name: "Sneasel",
    id: 215,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Teddiursa",
    id: 216,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Ursaring",
    id: 217,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Slugma",
    id: 218,
    shinyAvailable: true,
  },
  {
    name: "Magcargo",
    id: 219,
    shinyAvailable: true,
  },
  {
    name: "Swinub",
    id: 220,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Piloswine",
    id: 221,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Corsola",
    id: 222,
    shinyAvailable: true,
  },
  {
    name: "Remoraid",
    id: 223,
    shinyAvailable: true,
  },
  {
    name: "Octillery",
    id: 224,
    shinyAvailable: true,
  },
  {
    name: "Delibird",
    id: 225,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Mantine",
    id: 226,
    shinyAvailable: true,
  },
  {
    name: "Skarmory",
    id: 227,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Houndour",
    id: 228,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Houndoom",
    id: 229,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Kingdra",
    id: 230,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Phanpy",
    id: 231,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Donphan",
    id: 232,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Porygon2",
    id: 233,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Stantler",
    id: 234,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Smeargle",
    id: 235,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tyrogue",
    id: 236,
    shinyAvailable: true,
    gender: "male",
  },
  {
    name: "Hitmontop",
    id: 237,
    shinyAvailable: true,
    gender: "male",
    shadow: true,
  },
  {
    name: "Smoochum",
    id: 238,
    shinyAvailable: true,
    gender: "female",
  },
  {
    name: "Elekid",
    id: 239,
    shinyAvailable: true,
  },
  {
    name: "Magby",
    id: 240,
    shinyAvailable: true,
  },
  {
    name: "Miltank",
    id: 241,
    shinyAvailable: true,
    gender: "female",
  },
  {
    name: "Blissey",
    id: 242,
    shinyAvailable: true,
    gender: "female",
  },
  {
    name: "Raikou",
    id: 243,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Entei",
    id: 244,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Suicune",
    id: 245,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Larvitar",
    id: 246,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Pupitar",
    id: 247,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Tyranitar",
    id: 248,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Lugia",
    id: 249,
    shinyAvailable: true,
    shadow: true,
    gender: "none",
  },
  {
    name: "Ho-Oh",
    id: 250,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Celebi",
    id: 251,
    tradable: false,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Treecko",
    id: 252,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Grovyle",
    id: 253,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Sceptile",
    id: 254,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Torchic",
    id: 255,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Combusken",
    id: 256,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Blaziken",
    id: 257,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Mudkip",
    id: 258,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Marshtomp",
    id: 259,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Swampert",
    id: 260,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Poochyena",
    id: 261,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Mightyena",
    id: 262,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Zigzagoon",
    id: 263,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Linoone",
    id: 264,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Wurmple",
    id: 265,
    shinyAvailable: true,
  },
  {
    name: "Silcoon",
    id: 266,
    shinyAvailable: true,
  },
  {
    name: "Beautifly",
    id: 267,
    shinyAvailable: true,
  },
  {
    name: "Cascoon",
    id: 268,
    shinyAvailable: true,
  },
  {
    name: "Dustox",
    id: 269,
    shinyAvailable: true,
  },
  {
    name: "Lotad",
    id: 270,
    shinyAvailable: true,
  },
  {
    name: "Lombre",
    id: 271,
    shinyAvailable: true,
  },
  {
    name: "Ludicolo",
    id: 272,
    shinyAvailable: true,
  },
  {
    name: "Seedot",
    id: 273,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Nuzleaf",
    id: 274,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Shiftry",
    id: 275,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Taillow",
    id: 276,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Swellow",
    id: 277,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Wingull",
    id: 278,
    shinyAvailable: true,
  },
  {
    name: "Pelipper",
    id: 279,
    shinyAvailable: true,
  },
  {
    name: "Ralts",
    id: 280,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Kirlia",
    id: 281,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Gardevoir",
    id: 282,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Surskit",
    id: 283,
    shinyAvailable: true,
  },
  {
    name: "Masquerain",
    id: 284,
    shinyAvailable: true,
  },
  {
    name: "Shroomish",
    id: 285,
    shinyAvailable: true,
  },
  {
    name: "Breloom",
    id: 286,
    shinyAvailable: true,
  },
  {
    name: "Slakoth",
    id: 287,
    shinyAvailable: true,
  },
  {
    name: "Vigoroth",
    id: 288,
    shinyAvailable: true,
  },
  {
    name: "Slaking",
    id: 289,
    shinyAvailable: true,
  },
  {
    name: "Nincada",
    id: 290,
    shinyAvailable: true,
  },
  {
    name: "Ninjask",
    id: 291,
    shinyAvailable: true,
  },
  {
    name: "Shedinja",
    id: 292,
    shinyAvailable: false,
    gender: "none",
  },
  {
    name: "Whismur",
    id: 293,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Loudred",
    id: 294,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Exploud",
    id: 295,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Makuhita",
    id: 296,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Hariyama",
    id: 297,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Azurill",
    id: 298,
    shinyAvailable: true,
  },
  {
    name: "Nosepass",
    id: 299,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Skitty",
    id: 300,
    shinyAvailable: true,
    available: true,
  },
  {
    name: "Delcatty",
    id: 301,
    shinyAvailable: true,
  },
  {
    name: "Sableye",
    id: 302,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Mawile",
    id: 303,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Aron",
    id: 304,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Lairon",
    id: 305,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Aggron",
    id: 306,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Meditite",
    id: 307,
    shinyAvailable: true,
  },
  {
    name: "Medicham",
    id: 308,
    shinyAvailable: true,
  },
  {
    name: "Electrike",
    id: 309,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Manectric",
    id: 310,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Plusle",
    id: 311,
    shinyAvailable: true,
  },
  {
    name: "Minun",
    id: 312,
    shinyAvailable: true,
  },
  {
    name: "Volbeat",
    id: 313,
    shinyAvailable: true,
    gender: "male",
  },
  {
    name: "Illumise",
    id: 314,
    shinyAvailable: true,
    gender: "female",
  },
  {
    name: "Roselia",
    id: 315,
    shinyAvailable: true,
  },
  {
    name: "Gulpin",
    id: 316,
    shinyAvailable: true,
  },
  {
    name: "Swalot",
    id: 317,
    shinyAvailable: true,
  },
  {
    name: "Carvanha",
    id: 318,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Sharpedo",
    id: 319,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Wailmer",
    id: 320,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Wailord",
    id: 321,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Numel",
    id: 322,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Camerupt",
    id: 323,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Torkoal",
    id: 324,
    shinyAvailable: true,
  },
  {
    name: "Spoink",
    id: 325,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Grumpig",
    id: 326,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Spinda",
    id: 327,
    shinyAvailable: true,
  },
  {
    name: "Trapinch",
    id: 328,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Vibrava",
    id: 329,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Flygon",
    id: 330,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Cacnea",
    id: 331,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Cacturne",
    id: 332,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Swablu",
    id: 333,
    shinyAvailable: true,
  },
  {
    name: "Altaria",
    id: 334,
    shinyAvailable: true,
  },
  {
    name: "Zangoose",
    id: 335,
    shinyAvailable: true,
  },
  {
    name: "Seviper",
    id: 336,
    shinyAvailable: true,
  },
  {
    name: "Lunatone",
    id: 337,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Solrock",
    id: 338,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Barboach",
    id: 339,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Whiscash",
    id: 340,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Corphish",
    id: 341,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Crawdaunt",
    id: 342,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Baltoy",
    id: 343,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Claydol",
    id: 344,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Lileep",
    id: 345,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Cradily",
    id: 346,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Anorith",
    id: 347,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Armaldo",
    id: 348,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Feebas",
    id: 349,
    shinyAvailable: true,
  },
  {
    name: "Milotic",
    id: 350,
    shinyAvailable: true,
  },
  {
    name: "Castform",
    id: 351,
    shinyAvailable: true,
  },
  {
    name: "Kecleon",
    id: 352,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Shuppet",
    id: 353,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Banette",
    id: 354,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Duskull",
    id: 355,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Dusclops",
    id: 356,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Tropius",
    id: 357,
    shinyAvailable: true,
  },
  {
    name: "Chimecho",
    id: 358,
    shinyAvailable: true,
  },
  {
    name: "Absol",
    id: 359,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Wynaut",
    id: 360,
    shinyAvailable: true,
  },
  {
    name: "Snorunt",
    id: 361,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Glalie",
    id: 362,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Spheal",
    id: 363,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Sealeo",
    id: 364,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Walrein",
    id: 365,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Clamperl",
    id: 366,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Huntail",
    id: 367,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Gorebyss",
    id: 368,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Relicanth",
    id: 369,
    shinyAvailable: true,
  },
  {
    name: "Luvdisc",
    id: 370,
    shinyAvailable: true,
  },
  {
    name: "Bagon",
    id: 371,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Shelgon",
    id: 372,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Salamence",
    id: 373,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Beldum",
    id: 374,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Metang",
    id: 375,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Metagross",
    id: 376,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Regirock",
    id: 377,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Regice",
    id: 378,
    shinyAvailable: true,
    shadow: true,
    gender: "none",
  },
  {
    name: "Registeel",
    id: 379,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Latias",
    id: 380,
    shinyAvailable: true,
    gender: "female",
    shadow: true,
  },
  {
    name: "Latios",
    id: 381,
    shinyAvailable: true,
    gender: "male",
    shadow: true,
  },
  {
    name: "Kyogre",
    id: 382,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Groudon",
    id: 383,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Rayquaza",
    id: 384,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Jirachi",
    id: 385,
    available: true,
    shinyAvailable: true,
    gender: "none",
    tradable: false,
  },
  {
    name: "Deoxys",
    id: 386,
    tradable: false,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Turtwig",
    id: 387,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Grotle",
    id: 388,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Torterra",
    id: 389,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Chimchar",
    id: 390,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Monferno",
    id: 391,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Infernape",
    id: 392,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Piplup",
    id: 393,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Prinplup",
    id: 394,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Empoleon",
    id: 395,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Starly",
    id: 396,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Staravia",
    id: 397,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Staraptor",
    id: 398,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Bidoof",
    id: 399,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Bibarel",
    id: 400,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Kricketot",
    id: 401,
    shinyAvailable: true,
  },
  {
    name: "Kricketune",
    id: 402,
    shinyAvailable: true,
  },
  {
    name: "Shinx",
    id: 403,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Luxio",
    id: 404,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Luxray",
    id: 405,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Budew",
    id: 406,
    shinyAvailable: true,
  },
  {
    name: "Roserade",
    id: 407,
    shinyAvailable: true,
  },
  {
    name: "Cranidos",
    id: 408,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Rampardos",
    id: 409,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Shieldon",
    id: 410,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Bastiodon",
    id: 411,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Burmy",
    id: 412,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Wormadam",
    id: 413,
    available: true,
    shinyAvailable: true,
    gender: "female",
  },
  {
    name: "Mothim",
    id: 414,
    available: true,
    shinyAvailable: true,
    gender: "male",
  },
  {
    name: "Combee",
    id: 415,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Vespiquen",
    id: 416,
    available: true,
    shinyAvailable: true,
    gender: "female",
  },
  {
    name: "Pachirisu",
    id: 417,
    shinyAvailable: true,
  },
  {
    name: "Buizel",
    id: 418,
    shinyAvailable: true,
  },
  {
    name: "Floatzel",
    id: 419,
    shinyAvailable: true,
  },
  {
    name: "Cherubi",
    id: 420,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Cherrim",
    id: 421,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Shellos",
    id: 422,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Gastrodon",
    id: 423,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Ambipom",
    id: 424,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Drifloon",
    id: 425,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Drifblim",
    id: 426,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Buneary",
    id: 427,
    shinyAvailable: true,
  },
  {
    name: "Lopunny",
    id: 428,
    shinyAvailable: true,
  },
  {
    name: "Mismagius",
    id: 429,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Honchkrow",
    id: 430,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Glameow",
    id: 431,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Purugly",
    id: 432,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Chingling",
    id: 433,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Stunky",
    id: 434,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Skuntank",
    id: 435,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Bronzor",
    id: 436,
    available: true,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Bronzong",
    id: 437,
    available: true,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Bonsly",
    id: 438,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Mime Jr.",
    id: 439,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Happiny",
    id: 440,
    available: true,
    shinyAvailable: true,
    gender: "female",
  },
  {
    name: "Chatot",
    id: 441,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Spiritomb",
    id: 442,
    shinyAvailable: true,
  },
  {
    name: "Gible",
    id: 443,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Gabite",
    id: 444,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Garchomp",
    id: 445,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Munchlax",
    id: 446,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Riolu",
    id: 447,
    shinyAvailable: true,
  },
  {
    name: "Lucario",
    id: 448,
    shinyAvailable: true,
  },
  {
    name: "Hippopotas",
    id: 449,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Hippowdon",
    id: 450,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Skorupi",
    id: 451,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Drapion",
    id: 452,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Croagunk",
    id: 453,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Toxicroak",
    id: 454,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Carnivine",
    id: 455,
    shinyAvailable: true,
  },
  {
    name: "Finneon",
    id: 456,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Lumineon",
    id: 457,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Mantyke",
    id: 458,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Snover",
    id: 459,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Abomasnow",
    id: 460,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Weavile",
    id: 461,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Magnezone",
    id: 462,
    available: true,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Lickilicky",
    id: 463,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Rhyperior",
    id: 464,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Tangrowth",
    id: 465,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Electivire",
    id: 466,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Magmortar",
    id: 467,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Togekiss",
    id: 468,
    shinyAvailable: true,
  },
  {
    name: "Yanmega",
    id: 469,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Leafeon",
    id: 470,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Glaceon",
    id: 471,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Gliscor",
    id: 472,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Mamoswine",
    id: 473,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Porygon-z",
    id: 474,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Gallade",
    id: 475,
    available: true,
    shinyAvailable: true,
    gender: "male",
    shadow: true,
  },
  {
    name: "Probopass",
    id: 476,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Dusknoir",
    id: 477,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Froslass",
    id: 478,
    available: true,
    shinyAvailable: true,
    gender: "female",
    shadow: true,
  },
  {
    name: "Rotom",
    id: 479,
    available: true,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Uxie",
    id: 480,
    available: true,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Mesprit",
    id: 481,
    available: true,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Azelf",
    id: 482,
    available: true,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Dialga",
    id: 483,
    available: true,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Palkia",
    id: 484,
    available: true,
    shinyAvailable: true,
    shadow: true,
    gender: "none",
  },
  {
    name: "Heatran",
    id: 485,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Regigigas",
    id: 486,
    available: true,
    shinyAvailable: true,
    gender: "none",
    shadow: true,
  },
  {
    name: "Giratina",
    id: 487,
    shinyAvailable: true,
    gender: "none",
  },
  {
    name: "Cresselia",
    id: 488,
    shinyAvailable: true,
    gender: "female",
    shadow: true,
  },
  {
    name: "Phione",
    id: 489,
    available: false,
    shinyAvailable: false,
    gender: "none",
  },
  {
    name: "Manaphy",
    id: 490,
    available: false,
    shinyAvailable: false,
    gender: "none",
  },
  {
    name: "Darkrai",
    id: 491,
    available: true,
    shinyAvailable: true,
    gender: "none",
    tradable: false,
  },
  {
    name: "Shaymin",
    id: 492,
    available: true,
    shinyAvailable: true,
    tradable: false,
    gender: "none",
  },
  {
    name: "Arceus",
    id: 493,
    available: false,
    shinyAvailable: false,
    gender: "none",
  },
  {
    name: "Victini",
    id: 494,
    available: true,
    shinyAvailable: false,
    tradable: false,
  },
  {
    name: "Snivy",
    id: 495,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Servine",
    id: 496,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Serperior",
    id: 497,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Tepig",
    id: 498,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Pignite",
    id: 499,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Emboar",
    id: 500,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Oshawott",
    id: 501,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Dewott",
    id: 502,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Samurott",
    id: 503,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Patrat",
    id: 504,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Watchog",
    id: 505,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Lillipup",
    id: 506,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Herdier",
    id: 507,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Stoutland",
    id: 508,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Purrloin",
    id: 509,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Liepard",
    id: 510,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Pansage",
    id: 511,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Simisage",
    id: 512,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Pansear",
    id: 513,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Simisear",
    id: 514,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Panpour",
    id: 515,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Simipour",
    id: 516,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Munna",
    id: 517,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Musharna",
    id: 518,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Pidove",
    id: 519,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Tranquill",
    id: 520,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Unfezant",
    id: 521,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Blitzle",
    id: 522,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Zebstrika",
    id: 523,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Roggenrola",
    id: 524,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Boldore",
    id: 525,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Gigalith",
    id: 526,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Woobat",
    id: 527,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Swoobat",
    id: 528,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Drilbur",
    id: 529,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Excadrill",
    id: 530,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Audino",
    id: 531,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Timburr",
    id: 532,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Gurdurr",
    id: 533,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Conkeldurr",
    id: 534,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Tympole",
    id: 535,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Palpitoad",
    id: 536,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Seismitoad",
    id: 537,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Throh",
    id: 538,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Sawk",
    id: 539,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Sewaddle",
    id: 540,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Swadloon",
    id: 541,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Leavanny",
    id: 542,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Venipede",
    id: 543,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Whirlipede",
    id: 544,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Scolipede",
    id: 545,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Cottonee",
    id: 546,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Whimsicott",
    id: 547,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Petilil",
    id: 548,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Lilligant",
    id: 549,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Basculin",
    id: 550,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Sandile",
    id: 551,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Krokorok",
    id: 552,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Krookodile",
    id: 553,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Darumaka",
    id: 554,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Darmanitan",
    id: 555,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Maractus",
    id: 556,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Dwebble",
    id: 557,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Crustle",
    id: 558,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Scraggy",
    id: 559,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Scrafty",
    id: 560,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Sigilyph",
    id: 561,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Yamask",
    id: 562,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Cofagrigus",
    id: 563,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tirtouga",
    id: 564,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Carracosta",
    id: 565,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Archen",
    id: 566,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Archeops",
    id: 567,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Trubbish",
    id: 568,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Garbodor",
    id: 569,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Zorua",
    id: 570,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Zoroark",
    id: 571,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Mincinno",
    id: 572,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Cinccino",
    id: 573,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Gothita",
    id: 574,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Gothorita",
    id: 575,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Gothitelle",
    id: 576,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Solosis",
    id: 577,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Duosion",
    id: 578,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Reuniclus",
    id: 579,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Ducklett",
    id: 580,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Swanna",
    id: 581,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Vanillite",
    id: 582,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Vanillish",
    id: 583,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Vanilluxe",
    id: 584,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Deerling",
    id: 585,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Sawsbuck",
    id: 586,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Emolga",
    id: 587,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Karrablast",
    id: 588,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Escavalier",
    id: 589,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Foongus",
    id: 590,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Amoonguss",
    id: 591,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Frillish",
    id: 592,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Jellicent",
    id: 593,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Alomomola",
    id: 594,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Joltik",
    id: 595,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Galvantula",
    id: 596,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Ferroseed",
    id: 597,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Ferrothorn",
    id: 598,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Klink",
    id: 599,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Klang",
    id: 600,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Klinklang",
    id: 601,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tynamo",
    id: 602,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Eelektrik",
    id: 603,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Eelektross",
    id: 604,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Elgyem",
    id: 605,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Beeheeyem",
    id: 606,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Litwick",
    id: 607,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Lampent",
    id: 608,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Chandelure",
    id: 609,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Axew",
    id: 610,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Fraxure",
    id: 611,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Haxorus",
    id: 612,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Cubchoo",
    id: 613,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Beartic",
    id: 614,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Cryogonal",
    id: 615,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Shelmet",
    id: 616,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Accelgor",
    id: 617,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Stunfisk",
    id: 618,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Mienfoo",
    id: 619,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Mienshao",
    id: 620,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Druddigon",
    id: 621,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Golett",
    id: 622,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Golurk",
    id: 623,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Pawniard",
    id: 624,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Bisharp",
    id: 625,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Bouffalant",
    id: 626,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Rufflet",
    id: 627,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Braviary",
    id: 628,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Vullaby",
    id: 629,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Mandibuzz",
    id: 630,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Heatmor",
    id: 631,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Durant",
    id: 632,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Deino",
    id: 633,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Zwelious",
    id: 634,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Hydreigon",
    id: 635,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Larvesta",
    id: 636,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Volcarona",
    id: 637,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Cobalion",
    id: 638,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Terrakion",
    id: 639,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Virizion",
    id: 640,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tornadus",
    id: 641,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Thundurus",
    id: 642,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Reshiram",
    id: 643,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Zekrom",
    id: 644,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Landorus",
    id: 645,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Kyurem",
    id: 646,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Keldeo",
    id: 647,
    available: true,
    shinyAvailable: false,
    tradable: false,
  },
  {
    name: "Meloetta",
    id: 648,
    available: true,
    tradable: false,
    shinyAvailable: false,
  },
  {
    name: "Genesect",
    id: 649,
    available: true,
    shinyAvailable: true,
    tradable: false,
  },
  {
    name: "Chespin",
    id: 650,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Quilladin",
    id: 651,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Chesnaught",
    id: 652,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Fennekin",
    id: 653,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Braixen",
    id: 654,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Delphox",
    id: 655,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Froakie",
    id: 656,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Frogadier",
    id: 657,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Greninja",
    id: 658,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Bunnelby",
    id: 659,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Diggersby",
    id: 660,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Fletchling",
    id: 661,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Fletchinder",
    id: 662,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Talonflame",
    id: 663,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Scatterbug",
    id: 664,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Spewpa",
    id: 665,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Vivillon",
    id: 666,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Litleo",
    id: 667,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Pyroar",
    id: 668,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Flabébé",
    id: 669,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Floette",
    id: 670,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Florges",
    id: 671,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Skiddo",
    id: 672,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Gogoat",
    id: 673,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Pancham",
    id: 674,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Pangoro",
    id: 675,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Furfrou",
    id: 676,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Espurr",
    id: 677,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Meowstic",
    id: 678,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Honedge",
    id: 679,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Doublade",
    id: 680,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Aegislash",
    id: 681,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Spritzee",
    id: 682,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Aromatisse",
    id: 683,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Swirlix",
    id: 684,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Slurpuff",
    id: 685,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Inkay",
    id: 686,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Malamar",
    id: 687,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Binacle",
    id: 688,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Barbaracle",
    id: 689,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Skrelp",
    id: 690,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Dragalge",
    id: 691,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Clauncher",
    id: 692,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Clawitzer",
    id: 693,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Helioptile",
    id: 694,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Heliolisk",
    id: 695,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tyrunt",
    id: 696,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tyrantrum",
    id: 697,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Amaura",
    id: 698,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Aurorus",
    id: 699,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Sylveon",
    id: 700,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Hawlucha",
    id: 701,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Dedenne",
    id: 702,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Carbink",
    id: 703,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Goomy",
    id: 704,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Sliggoo",
    id: 705,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Goodra",
    id: 706,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Klefki",
    id: 707,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Phantump",
    id: 708,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Trevenant",
    id: 709,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Pumpkaboo",
    id: 710,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Gourgeist",
    id: 711,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Bergmite",
    id: 712,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Avalugg",
    id: 713,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Noibat",
    id: 714,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Noivern",
    id: 715,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Xerneas",
    id: 716,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Yveltal",
    id: 717,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Zygarde",
    id: 718,
    available: true,
    shinyAvailable: false,
    tradable: false,
  },
  {
    name: "Diancie",
    id: 719,
    available: true,
    shinyAvailable: false,
    tradable: false,
  },
  {
    name: "Hoopa",
    id: 720,
    available: true,
    tradable: false,
    shinyAvailable: false,
  },
  {
    name: "Volcanion",
    id: 721,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Rowlet",
    id: 722,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Dartrix",
    id: 723,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Decidueye",
    id: 724,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Litten",
    id: 725,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Torracat",
    id: 726,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Incineroar",
    id: 727,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Popplio",
    id: 728,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Brionne",
    id: 729,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Primarina",
    id: 730,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Pikipek",
    id: 731,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Trumbeak",
    id: 732,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Toucannon",
    id: 733,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Yungoos",
    id: 734,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Gumshoos",
    id: 735,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Grubbin",
    id: 736,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Charjabug",
    id: 737,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Vikavolt",
    id: 738,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Crabrawler",
    id: 739,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Crabominable",
    id: 740,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Oricorio",
    id: 741,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Cutiefly",
    id: 742,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Ribombee",
    id: 743,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Rockruff",
    id: 744,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Lycanroc",
    id: 745,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Wishiwashi",
    id: 746,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Mareanie",
    id: 747,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Toxapex",
    id: 748,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Mudbray",
    id: 749,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Mudsdale",
    id: 750,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Dewpider",
    id: 751,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Araquanid",
    id: 752,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Fomantis",
    id: 753,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Lurantis",
    id: 754,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Morelull",
    id: 755,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Shiinotic",
    id: 756,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Salandit",
    id: 757,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Salazzle",
    id: 758,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Stufful",
    id: 759,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Bewear",
    id: 760,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Bounsweet",
    id: 761,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Steenee",
    id: 762,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tsareena",
    id: 763,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Comfey",
    id: 764,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Oranguru",
    id: 765,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Passimian",
    id: 766,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Wimpod",
    id: 767,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Golisopod",
    id: 768,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Sandygast",
    id: 769,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Palossand",
    id: 770,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Pyukumuku",
    id: 771,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Type: Null",
    id: 772,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Silvally",
    id: 773,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Minior",
    id: 774,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Komala",
    id: 775,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Turtonator",
    id: 776,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Togedemaru",
    id: 777,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Mimikyu",
    id: 778,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Bruxish",
    id: 779,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Drampa",
    id: 780,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Dhelmise",
    id: 781,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Jangmo-o",
    id: 782,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Hakamo-o",
    id: 783,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Kommo-o",
    id: 784,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tapu Koko",
    id: 785,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tapu Lele",
    id: 786,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tapu Bulu",
    id: 787,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tapu Fini",
    id: 788,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Cosmog",
    id: 789,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Cosmoem",
    id: 790,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Solgaleo",
    id: 791,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Lunala",
    id: 792,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Nihilego",
    id: 793,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Buzzwole",
    id: 794,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Pheromosa",
    id: 795,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Xurkitree",
    id: 796,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Celesteela",
    id: 797,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Kartana",
    id: 798,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Guzzlord",
    id: 799,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Necrozma",
    id: 800,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Magearna",
    id: 801,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Marshadow",
    id: 802,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Poipole",
    id: 803,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Naganadel",
    id: 804,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Stakataka",
    id: 805,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Blacephalon",
    id: 806,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Zeraora",
    id: 807,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Meltan",
    id: 808,
    shinyAvailable: true,
  },
  {
    name: "Melmetal",
    id: 809,
    shinyAvailable: true,
  },
  {
    name: "Grookey",
    id: 810,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Thwackey",
    id: 811,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Rillaboom",
    id: 812,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Scorbunny",
    id: 813,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Raboot",
    id: 814,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Cinderace",
    id: 815,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Sobble",
    id: 816,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Drizzile",
    id: 817,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Inteleon",
    id: 818,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Skwovet",
    id: 819,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Greedent",
    id: 820,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Rookidee",
    id: 821,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Corvisquire",
    id: 822,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Corviknight",
    id: 823,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Blipbug",
    id: 824,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Dottler",
    id: 825,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Orbeetle",
    id: 826,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Nickit",
    id: 827,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Thievul",
    id: 828,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Gossifleur",
    id: 829,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Eldegoss",
    id: 830,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Wooloo",
    id: 831,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Dubwool",
    id: 832,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Chewtle",
    id: 833,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Drednaw",
    id: 834,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Yamper",
    id: 835,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Boltund",
    id: 836,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Rolycoly",
    id: 837,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Carkol",
    id: 838,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Coalossal",
    id: 839,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Applin",
    id: 840,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Flapple",
    id: 841,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Appletun",
    id: 842,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Silicobra",
    id: 843,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Sandaconda",
    id: 844,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Cramorant",
    id: 845,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Arrokuda",
    id: 846,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Barraskewda",
    id: 847,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Toxel",
    id: 848,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Toxtricity",
    id: 849,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Sizzlipede",
    id: 850,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Centiskorch",
    id: 851,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Clobbopus",
    id: 852,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Grapploct",
    id: 853,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Sinistea",
    id: 854,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Polteageist",
    id: 855,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Hatenna",
    id: 856,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Hattrem",
    id: 857,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Hatterene",
    id: 858,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Impidimp",
    id: 859,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Morgrem",
    id: 860,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Grimmsnarl",
    id: 861,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Obstagoon",
    id: 862,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Perrserker",
    id: 863,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Cursola",
    id: 864,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Sirfetch’d",
    id: 865,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Mr. Rime",
    id: 866,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Runerigus",
    id: 867,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Milcery",
    id: 868,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Alcremie",
    id: 869,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Falinks",
    id: 870,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Pincurchin",
    id: 871,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Snom",
    id: 872,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Frosmoth",
    id: 873,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Stonjourner",
    id: 874,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Eiscue",
    id: 875,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Indeedee",
    id: 876,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Morpeko",
    id: 877,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Cufant",
    id: 878,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Copperajah",
    id: 879,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Dracozolt",
    id: 880,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Arctozolt",
    id: 881,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Dracovish",
    id: 882,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Arctovish",
    id: 883,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Duraludon",
    id: 884,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Dreepy",
    id: 885,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Drakloak",
    id: 886,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Dragapult",
    id: 887,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Zacian",
    id: 888,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Zamazenta",
    id: 889,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Eternatus",
    id: 890,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Kubfu",
    id: 891,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Urshifu",
    id: 892,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Zarude",
    id: 893,
    available: true,
    tradable: false,
    shinyAvailable: false,
  },
  {
    name: "Regieleki",
    id: 894,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Regidrago",
    id: 895,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Glastrier",
    id: 896,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Spectrier",
    id: 897,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Calyrex",
    id: 898,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Wyrdeer",
    id: 899,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Kleavor",
    id: 900,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Ursaluna",
    id: 901,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Basculegion",
    id: 902,
    available: false,
    shinyAvailable: false,
  },
  {
    name: "Sneasler",
    id: 903,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Overqwil",
    id: 904,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Enamorus",
    id: 905,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Sprigatito",
    id: 906,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Floragato",
    id: 907,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Meowscarada",
    id: 908,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Fuecoco",
    id: 909,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Crocalor",
    id: 910,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Skeledirge",
    id: 911,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Quaxly",
    id: 912,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Quaxwell",
    id: 913,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Quaquaval",
    id: 914,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Lechonk",
    id: 915,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Oinkologne",
    id: 916,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Nymble",
    id: 919,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Lokix",
    id: 920,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Pawmi",
    id: 921,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Pawmo",
    id: 922,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Pawmot",
    id: 923,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Tandemaus",
    id: 924,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Maushold",
    id: 925,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Fidough",
    id: 926,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Dachsbun",
    id: 927,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Smoliv",
    id: 928,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Dolliv",
    id: 929,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Arboliva",
    id: 930,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Charcadet",
    id: 935,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Armarouge",
    id: 936,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Ceruledge",
    id: 937,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Tadbulb",
    id: 938,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Bellibolt",
    id: 939,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Shroodle",
    id: 944,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Grafaiai",
    id: 945,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Wiglett",
    id: 960,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Wugtrio",
    id: 961,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Bombirdier",
    id: 962,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Varoom",
    id: 965,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Revaroom",
    id: 966,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Greavard",
    id: 971,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Houndstone",
    id: 972,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Cetoddle",
    id: 974,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Cetitan",
    id: 975,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Annihilape",
    id: 979,
    available: true,
    shinyAvailable: true,
    shadow: true,
  },
  {
    name: "Clodsire",
    id: 980,
    available: true,
    shinyAvailable: true,
  },
  {
    name: "Frigibax",
    id: 996,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Arctibax",
    id: 997,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Baxcalibur",
    id: 998,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Gimmighoul",
    id: 999,
    available: true,
    shinyAvailable: false,
  },
  {
    name: "Gholdengo",
    id: 1000,
    available: true,
    shinyAvailable: false,
  },
];

export default pokemon;
